import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { RingBaseSelect } from '../components/RingBaseSelect'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="カスタマイズ、試着、オーダーメイドまでのリングご注文の流れ | 結婚指輪CAD-platinum"
        description="10,000点を超えるデザインからカスタマイズしたなら、お好みのリングを自宅で試着し、アレンジを加えてオーダーメイド。世界でひとつのオリジナルリングご注文の流れをご案内。理想と出会えるCAD-platinumで、一生に一度の記念品にふさわしい結婚指輪をお求め下さい。"
        page="trying-on"
    />
)

const TryingOnPage: React.FC = () => {
    return (
        <Layout breadcrumbs={[{ name: 'ご注文の流れ' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <CatchWrapper>
                        <CatchTxt size="l">Trying on</CatchTxt>
                        <Txt>試着からご注文まで</Txt>
                    </CatchWrapper>
                    <HeroImageWrapper>
                        <StaticImage src="../images/trying-on/t1.jpg" alt="" />
                    </HeroImageWrapper>
                    <CatchWrapper>
                        <CatchTxt size="l">Unique</CatchTxt>
                        <Txt>10,000通りから運命の1本へ</Txt>
                    </CatchWrapper>
                    <TryingOnWrapper>
                        <LeftWrapper>
                            <TryingOnTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/01.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <TryingOnTitle size="l">
                                    まずはスマホでカスタマイズ
                                </TryingOnTitle>
                            </TryingOnTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/customize/g5.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </LeftWrapper>
                        <TryingOnDescription size="s">
                            10,000通りを超えるデザイン、ジュエリー加工を自由に組み合わせ、お好みのリングを気軽にチェック。
                        </TryingOnDescription>
                    </TryingOnWrapper>
                    <TryingOnWrapper>
                        <RightWrapper>
                            <TryingOnTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/02.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <TryingOnTitle size="l">
                                    詳細ページで試着リングを注文
                                </TryingOnTitle>
                            </TryingOnTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/customize/g6.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </RightWrapper>
                        <TryingOnDescription size="s">
                            カスタマイズで見つけたお好みのリング詳細ページから試着リングの無料配送をお申込み頂けます。
                        </TryingOnDescription>
                    </TryingOnWrapper>
                    <TryingOnWrapper>
                        <LeftWrapper>
                            <TryingOnTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/03.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <TryingOnTitle size="l">
                                    ご自宅または店舗で試着
                                </TryingOnTitle>
                            </TryingOnTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/customize/g7.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </LeftWrapper>
                        <TryingOnDescription size="s">
                            お好みのカスタマイズリングとスタッフのおすすめリングを無料配送。返却はポストに投函で完了。サイズ測定ゲージも同封いたします。店舗でご試着をご希望の場合は、表参道ショールームのご予約でご利用頂けます。
                        </TryingOnDescription>
                    </TryingOnWrapper>
                    <TryingOnWrapper>
                        <RightWrapper>
                            <TryingOnTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/04.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <TryingOnTitle size="l">
                                    アレンジのお問合わせ、お見積もり
                                </TryingOnTitle>
                            </TryingOnTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/trying-on/t2.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </RightWrapper>
                        <TryingOnDescription size="s">
                            オリジナルのアレンジをご希望の場合はカスタマイズリング詳細画面よりアレンジをお問合せメールを送信頂きます。お見積もりと併せアレンジ画像をご案内させて頂きます。
                        </TryingOnDescription>
                    </TryingOnWrapper>
                    <TryingOnWrapper>
                        <LeftWrapper>
                            <TryingOnTitleWrapper>
                                <NumberImageWrapper>
                                    <StaticImage
                                        src="../images/number/05.jpg"
                                        alt=""
                                    />
                                </NumberImageWrapper>
                                <TryingOnTitle size="l">
                                    ご注文も公式ホームページから
                                </TryingOnTitle>
                            </TryingOnTitleWrapper>
                            <FeatureImageWrapper>
                                <StaticImage
                                    src="../images/trying-on/t3.jpg"
                                    alt=""
                                />
                            </FeatureImageWrapper>
                        </LeftWrapper>
                        <TryingOnDescription size="s">
                            単品でも2本ペアでも、ご注文はカスタマイズリングの詳細画面からご利用頂けます。返品返金保証、修理補償、リングケース、品質保証書付きで納期2週間前後で出荷となります。
                        </TryingOnDescription>
                    </TryingOnWrapper>
                    <RingBaseSelect marginTop="50px" />
                </Grid>
            </Grid>
        </Layout>
    )
}

const CatchWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '16px 0',
})
const CatchTxt = styled(Txt)({
    marginBottom: '16px',
})
const HeroImageWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})
const TryingOnWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: '48px',
})
const LeftWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
})
const RightWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
})
const TryingOnTitleWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
})
const TryingOnTitle = styled(Txt)({
    padding: '8px',
})
const NumberImageWrapper = styled('div')({
    width: '80px',
})
const FeatureImageWrapper = styled('div')({
    width: '80%',
})
const TryingOnDescription = styled(Txt)({
    padding: '8px',
})

export default TryingOnPage
